import firebase from  "firebase";
import { getConfig } from '../config/config';

const firebaseConfig = getConfig('FIREBASE_CONFIG');

!firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app();

export const database = firebase.firestore();
export const auth = firebase.auth();

export const sendPasswordResetEmail = async (email) => {
  await firebase.auth().sendPasswordResetEmail(email);
}

export const signUp = async (email, password) => {
  await firebase.auth().createUserWithEmailAndPassword(email, password);
}

export const signIn = async (email, password) => {
  console.log(email, password);
  await firebase.auth().signInWithEmailAndPassword(email, password);
}

export const signOut = async () => {
  return firebase.auth().signOut();
}

export const updatePassword = async (password) => {
  try {
    const user = await firebase.auth().currentUser;
    if (user) {
      return user.updatePassword(password);
    }
  } catch (err) {
    throw new Error(err);
  }

  return false;
}

export const signInWithFacebook = async () => {
  const provider = new firebase.auth.FacebookAuthProvider();
  await firebase.auth().signInWithPopup(provider);
}

export const signInWithGoogle = async () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  await firebase.auth().signInWithPopup(provider);
}

export const isSignedIn = () => {
  return firebase.auth().currentUser != null;
}

export const fetchUserInfo = async (userId) => {
  try {
    const userRef = firebase.firestore().collection(`users`).doc(userId);

    return userRef.get().then((doc) => {
      if (doc.exists) {
        return doc.data();
      }
      return {};
    }).catch((error) => error);
  } catch (error) {
    return error;
  }
}

export { firebase };