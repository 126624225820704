import React, { useState, useEffect} from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { firebase, fetchUserInfo } from './lib/firebase';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = () => {
  const[auth, setAuth] = useState(false);
  const [userData, setUserData] = useState(null)

  useEffect(() => firebase.auth().onAuthStateChanged(user => {
    async function init() {
      if (user) {
        const userData = await fetchUserInfo(user.uid)
        setAuth(() => {
          setUserData({user, ...userData})
          return true
        });
      } else {
        setAuth(false);
        setUserData(false)
      }
    }
    init()
  }), [setAuth, setUserData]);

  if (auth){
    console.log(userData.type);
    if (userData.type === 'admin' || userData.type === 'support'){
      return (
        <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                userData={userData}
                authenticated={auth}
                path="/"
                name="Home"
                render={props => <TheLayout {...props} />}
              />
            </Switch>
          </React.Suspense>
        </HashRouter>
      )
    }
  }

  return (
    <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
            <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
            <Redirect to="/login" />
          </Switch>
        </React.Suspense>
    </HashRouter>
  );
}

export default App;
