const API_URL_BASE =
  "https://us-central1-fd-swap-app-prod-1.cloudfunctions.net";

export function getConfig(key) {
  const base = {
    SWAP_API_ENDPOINT:
      "http://localhost:5000/fd-swap-app-prod-1/us-central1/api",
    // REACT_APP_CLOUD_API_ENDPOINT: 'http://localhost:5001/fd-swap-app-prod-1/us-central1/api_v2', // Local
    REACT_APP_CLOUD_API_ENDPOINT: `${API_URL_BASE}/api_v2`,
    FIREBASE_CONFIG: {
      apiKey: "AIzaSyChjNp9tctkkZWX-QhNdOTVLCVQg6y8GDA",
      authDomain: "fd-swap-app-prod-1.firebaseapp.com",
      databaseURL: "https://fd-swap-app-prod-1.firebaseio.co",
      projectId: "fd-swap-app-prod-1",
      storageBucket: "fd-swap-app-prod-1.appspot.com",
      messagingSenderId: "643780589845",
      appId: "1:643780589845:web:5adb67f6f609e68fe2111c",
    },
  };

  const development = {
    ...base,
  };

  const qa = {
    ...base,
    SWAP_API_ENDPOINT:
      "https://us-central1-for-days-dev-40b69.cloudfunctions.net/api",
    REACT_APP_CLOUD_API_ENDPOINT: `${API_URL_BASE}/api_qa`,
  };

  const staging = {
    ...base,
    SWAP_API_ENDPOINT:
      "https://us-central1-fd-swap-app-prod-1.cloudfunctions.net/api",
    REACT_APP_CLOUD_API_ENDPOINT: `${API_URL_BASE}/api_staging`,
  };

  const production = {
    ...base,
    SWAP_API_ENDPOINT:
      "https://us-central1-fd-swap-app-prod-1.cloudfunctions.net/api",
    REACT_APP_CLOUD_API_ENDPOINT: `${API_URL_BASE}/api_v2`,
  };

  const configSettings = {
    development,
    qa,
    staging,
    production,
  };

  let env = "production";
  if (typeof process !== "undefined") {
    env = process?.env?.REACT_APP_ENV
      ? process?.env?.REACT_APP_ENV
      : process?.env?.NODE_ENV || "production";
  }

  const config = configSettings[env] || configSettings.development;

  if (!key) {
    return config;
  }

  return config[key];
}
